/* ----- Style ----- */
import './style'

/* ----- TAC ----- */
const { tarteaucitron } = require('exports-loader?exports=tarteaucitron!tarteaucitronjs/tarteaucitron')
global.tarteaucitron = tarteaucitron

/* ----- Variables ----- */
const privacyPolicyUrl = document.querySelector('.js-privacy-policy-url') || '#'

/* ----- Functions ----- */
tarteaucitron.engage = function (id, translation_key) {
  'use strict'

  if (translation_key === null || translation_key === undefined || !tarteaucitron.lang.hasOwnProperty(translation_key)) {
    translation_key = 'fallback'
  }

  var html = '',
    r = Math.floor(Math.random() * 100000),
    engage = tarteaucitron.services[id].name + ' ' + tarteaucitron.lang[translation_key]

  if (tarteaucitron.lang['engage-' + id] !== undefined) {
    engage = tarteaucitron.lang['engage-' + id]
  }

  html += '<div class="tac_activate tac_activate_' + id + ' tac_activate--' + translation_key + '">'
  html += '   <div class="tac_float">'
  html += '      ' + engage
  html += '      <button type="button" class="tarteaucitronAllow" id="Eng' + r + 'ed' + id + '">'
  html += '          <span class="tarteaucitronCheck"></span> ' + tarteaucitron.lang.allow
  html += '       </button>'
  html += '   </div>'
  html += '</div>'

  return html
}

tarteaucitron.fixSelfXSS = function(html) { // Opsone fix for strict mode
  return html.toString().replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;')
}

tarteaucitron.getLanguage = function() {
  return document.documentElement.lang.substr(0, 2)
}

// Fix function error (remove if fixed in library)
tarteaucitron.triggerJobsAfterAjaxCall = function() {
  tarteaucitron.job.forEach(function (e) {
    tarteaucitron.job.push(e);
  });
  var allowBtns = document.getElementsByClassName("tarteaucitronAllow");

  for (var i = 0; i < allowBtns.length; i++) {
    tarteaucitron.addClickEventToElement(allowBtns[i], function () {
      tarteaucitron.userInterface.respond(this, true);
    });
  }

  var denyBtns = document.getElementsByClassName("tarteaucitronDeny");

  for (var i = 0; i < denyBtns.length; i++) {
    tarteaucitron.addClickEventToElement(denyBtns[i], function () {
      tarteaucitron.userInterface.respond(this, false);
    });
  }
}

/* ----- Locales ----- */
switch (tarteaucitron.getLanguage()) {
  case 'fr':
    require('tarteaucitronjs/lang/tarteaucitron.fr.js')
    break
  default:
    require('tarteaucitronjs/lang/tarteaucitron.en.js')
}

const customText = {
  "en": {
    "alertBigPrivacy": `We use cookies on this website to improve your user experience.<br> Your preferences can be changed at any time by clicking on <span class="o-nowrap">« Manage services »</span> at the bottom right of our pages.<span class="privacy-policy-block"><br> To learn more, read our <a href="${privacyPolicyUrl}">Privacy policy</a>.</span>`,
    "middleBarHead": "Manage my cookies",
    "disclaimer": `By allowing these third party services, you accept their cookies and the use of tracking technologies necessary for their proper functioning.<br> Your preferences can be changed at any time by clicking on <span class="o-nowrap">« Manage services »</span> at the bottom right of our pages.<br> To learn more, read our <a href="${privacyPolicyUrl}">Privacy policy</a>.`,
    "fallback_required": "is disabled.<br /> You must allow this service to validate this form.",
  },
  "fr": {
    "alertBigPrivacy": `Nous utilisons des cookies pour améliorer votre expérience utilisateur.<br> Vos préférences sont modifiables à tout moment en cliquant sur <span class="o-nowrap">« Gestion des services »</span> en bas à droite de nos pages.<span class="privacy-policy-block"><br> Pour en savoir plus, consultez notre <a href="${privacyPolicyUrl}">Politique de confidentialité</a>.</span>`,
    "middleBarHead": "Gérer mes cookies",
    "disclaimer": `En autorisant ces services tiers, vous acceptez le dépôt et la lecture de cookies et l'utilisation de technologies de suivi nécessaires à leur bon fonctionnement.<br> Vos préférences sont modifiables à tout moment en cliquant sur <span class="o-nowrap">« Gestion des services »</span> en bas à droite de nos pages.<br> Pour en savoir plus, consultez notre <a href="${privacyPolicyUrl}">Politique de confidentialité</a>.`,
    "fallback_required": "est désactivé.<br /> Vous devez autoriser ce service pour valider le formulaire.",
  },
}

let tarteaucitronCustomText = tarteaucitron.getLanguage() in customText ? customText[tarteaucitron.getLanguage()] : ''
tarteaucitron.lang = { ...tarteaucitron.lang, ...tarteaucitronCustomText }

/* ----- Services ----- */
require('tarteaucitronjs/tarteaucitron.services.js')

tarteaucitron.services.analytics.name = 'Google Analytics'

tarteaucitron.services.analytics.js = function() {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'TAC_GGA_accepte',
    TAC_GGA: 'true'
  })
}

tarteaucitron.services.analytics.fallback = function() {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'TAC_GGA_refus',
    TAC_GGA: 'false'
  })
}

tarteaucitron.services.mtcaptcha = {
  "key": "mtcaptcha",
  "type": "api",
  "name": "MTCaptcha",
  "uri": "https://www.mtcaptcha.com",
  "readmoreLink": "https://www.mtcaptcha.com/faq-cookie-declaration",
  "needConsent": true,
  "cookies": ['mtv1Pulse', 'mtv1ConfSum', 'mtv1Pong', 'mtv1*'],
  "js": function () {
    "use strict";
    if (document.querySelector('.mtcaptcha')) {
      (function(){var mt_service = document.createElement('script');mt_service.async = true;mt_service.src = 'https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js';(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(mt_service);
      var mt_service2 = document.createElement('script');mt_service2.async = true;mt_service2.src = 'https://service2.mtcaptcha.com/mtcv1/client/mtcaptcha2.min.js';(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(mt_service2);}) ();
    }
  },
  "fallback": function () {
    "use strict";
    var id = 'mtcaptcha';
    tarteaucitron.fallback(['mtcaptcha'], tarteaucitron.engage(id, 'fallback_required'));
  }
}

/* ----- Initialization ----- */
tarteaucitron.init({
  privacyUrl: privacyPolicyUrl, /* Privacy policy url */
  hashtag: '#tarteaucitron', /* Open the panel with this hashtag */
  cookieName: 'tarteaucitron', /* Cookie name */
  orientation: 'middle', /* Banner position (top - bottom) */
  groupServices: false, /* Group services by category */
  showAlertSmall: true, /* Show the small banner on bottom right */
  cookieslist: false, /* Show the cookie list */
  closePopup: false, /* Show a close X on the banner */
  showIcon: false, /* Show cookie icon to manage cookies */
  //iconSrc: '', /* Optionnal: URL or base64 encoded image */
  iconPosition: 'BottomRight', /* BottomRight, BottomLeft, TopRight and TopLeft */
  adblocker: false, /* Show a Warning if an adblocker is detected */
  denyAllCta: true, /* Show the deny all button */
  acceptAllCta: true, /* Show the accept all button when highPrivacy on */
  highPrivacy: true, /* HIGHLY RECOMMANDED Disable auto consent */
  handleBrowserDNTRequest: false, /* If Do Not Track == 1, disallow all */
  removeCredit: true, /* Remove credit link */
  moreInfoLink: true, /* Show more info link */
  useExternalCss: true, /* If false, the tarteaucitron.css file will be loaded */
  useExternalJs: true, /* If false, the tarteaucitron.js file will be loaded */
  //cookieDomain: '.my-multisite-domaine.fr', /* Shared cookie for multisite */
  readmoreLink: '', /* Change the default readmore link */
  mandatory: true, /* Show a message about mandatory cookies */
})

/* ----- Add services to TAC ----- */
tarteaucitron.job = []


// Google Analytics
tarteaucitron.job.push('analytics')


// MTCaptcha
tarteaucitron.job.push('mtcaptcha')
